//importing dependencies and packages
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import IMAGENAME from "../../../medias/media.index";
import { useSelector, useDispatch } from "react-redux";
import "./reportsStyle.css";
import S3BucketImage from "./s3BucketImages";

// function of Inspection Report Preview page to download report
const InspectionReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = useParams();
  const [imageSrc, setImageSrc] = useState([]);
  const [key, setKey] = useState("");
  const [selectedItem, setSelectedItem] = useState("Asset");

  const insReportData = useSelector((state) => state.inspection.insReportData);

  if (insReportData !== null) {
    var inspCompletedTime = moment(insReportData.inspection.ins_sch_date)
      .utc()
      .format("LLL");
  }

  // console.log("insReportData", insReportData);

  /////////////dispatch function to get inspection report data////////////
  const getInsReport = (insId) => {
    dispatch({
      type: "INSPECTION_REPORT",
      payload: insId,
    });
  };
  if (insReportData !== null) {
    const date = new Date(insReportData.inspection.ins_completed_date);
    var newDate = subtractSeconds(
      date,
      insReportData.inspection.inspection_duration
    );
  }
  //useEffects
  useEffect(() => {
    if (insReportData !== null) {
      // console.log(insReportData);
    }
  }, [insReportData]);

  useEffect(() => {
    if (id !== undefined && id !== null) {
      getInsReport(id);
    }
    window.scrollTo(0, 0);
  }, []);

  //function to calculate start date(End date-Inspection Duration)
  function subtractSeconds(date, seconds) {
    date.setSeconds(date.getSeconds() - seconds);
    return date;
  }

  useEffect(() => {
    // console.log(location);
    // console.log(location.state);
    // console.log(location.state[0]);
    if (location.state !== undefined && location.state !== null) {
      setKey(location.state?.[0]);
      setSelectedItem(location.state?.[1]);
    }
  }, []);

  useEffect(() => {
    // { console.log('imageSrc', imageSrc) }
  }, [imageSrc]);

  const convertToDMS = (coordinate, isLatitude) =>{
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

    const direction = isLatitude
        ? coordinate >= 0 ? 'N' : 'S'
        : coordinate >= 0 ? 'E' : 'W';

    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
  }


  ///////////////////////////////HTML//////////////////////////////////
  return (
    <div style={{ margin: "auto", width: 1000, border: "1px solid black" }}>
      {insReportData !== undefined &&
        (insReportData?.inspection?.trans_status === "approved" ||
          insReportData?.inspection?.trans_status === "auto_approved") && (
          <table
            className="table"
            style={{
              margin: "auto",
              width: 1000,
              border: "1px solid black",
              borderBottom: "none",
              tableLayout: "fixed",
              marginLeft: "auto",
            }}
          >
            <thead style={{ width: "100%" }}>
              <tr>
                <th
                  scope="col"
                  style={{
                    border: "1px solid",
                    width: "200px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <img
                    src={IMAGENAME.logo}
                    alt="ZongoVita logo"
                    style={{
                      width: "150px",
                      alignItems: "center",
                      // padding: '0.2rem',
                      // margin: 'auto',
                    }}
                  />
                </th>
                <th
                  colSpan="5"
                  style={{
                    border: "1px solid",
                    textAlign: "center",
                    verticalAlign: "middle",
                    fontWeight: "normal",
                  }}
                >
                  {`${insReportData.site.site_name} - ${insReportData.site.city}, ${insReportData?.site?.state}`}
                  <br />
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {`Report : ${insReportData.inspection.inspection_name}`}
                  </span>
                </th>
                <th
                  style={{
                    border: "1px solid",
                    width: "200px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <S3BucketImage
                    imageUrl={insReportData.customer.cust_logo}
                    widthSize={"auto"}
                  />
                </th>
              </tr>
              <tr style={{ verticalAlign: "middle" }}>
                <th colSpan="3" style={{ border: "1px solid" }}>
                  {insReportData?.inspection?.start_date !== null ? (
                    <div style={{ width: "100%" }}>
                      {insReportData?.inspection?.start_date ? (
                        <p style={{ verticalAlign: "middle", margin: "0px" }}>
                          {" "}
                          Start Date & Time -{" "}
                          {`${moment(
                            insReportData?.inspection?.start_date
                          ).format("MMMM Do YYYY | hh:mm:ss A")}`}{" "}
                        </p>
                      ) : (
                        <p style={{ verticalAlign: "middle", margin: "0px" }}>
                          {" "}
                          Start Date & Time -{" "}
                          {`${moment(newDate).format(
                            "MMMM Do YYYY | hh:mm:ss A"
                          )}`}{" "}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      {/* <p>Start Date & Time - NA </p> */}
                      <p style={{ verticalAlign: "middle", margin: "0px" }}>
                        {" "}
                        Start Date & Time - NA{" "}
                      </p>
                    </div>
                  )}
                </th>
                <th colSpan="3" style={{ border: "1px solid" }}>
                  {insReportData?.inspection?.end_date !== null ? (
                    <div style={{ width: "100%" }}>
                      {insReportData?.inspection?.end_date ? (
                        <p style={{ verticalAlign: "middle", margin: "0px" }}>
                          End Date & Time -{" "}
                          {`${moment(
                            insReportData?.inspection?.end_date
                          ).format("MMMM Do YYYY | hh:mm:ss A")}`}{" "}
                        </p>
                      ) : (
                        <p style={{ verticalAlign: "middle", margin: "0px" }}>
                          End Date & Time -{" "}
                          {`${moment(
                            insReportData?.inspection?.ins_sch_date
                          ).format("MMMM Do YYYY | hh:mm:ss A")}`}{" "}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      <p style={{ verticalAlign: "middle", margin: "0px" }}>
                        End Date & Time - NA{" "}
                      </p>
                    </div>
                  )}
                </th>
                <th
                  colSpan="1"
                  style={{ border: "1px solid", textAlign: "center" }}
                >
                  Priority - &nbsp;
                  {insReportData?.inspection?.priority
                    ?.charAt(0)
                    ?.toUpperCase() +
                    insReportData?.inspection?.priority?.slice(1)}
                </th>
              </tr>
              <tr>
                <th
                  colSpan="3"
                  style={{ border: "1px solid" }}
                >{`Inspection Duration (hh:mm:ss) - 
                    ${
                      moment
                        .utc(
                          moment
                            .duration(
                              insReportData.inspection.inspection_duration,
                              "seconds"
                            )
                            .asSeconds() * 1000
                        )
                        .format("HH:mm:ss")
                      // moment().seconds(insReportData.inspection.inspection_duration).format("hh:mm:ss")
                    }`}</th>
                <th colSpan="4" style={{ border: "1px solid" }}>
                  {insReportData?.inspection?.trans_status === "auto_approved"
                    ? "Approved Date & Time - Auto Approved"
                    : `Approved Date & Time - 
                   ${moment(
                     insReportData?.inspection?.ins_completed_date
                   ).format("MMMM Do YYYY | hh:mm:ss A")}`}
                </th>
              </tr>
              <tr>
                <th
                  colSpan="7"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#0862B4",
                    color: "white",
                    textAlign: "center",
                    borderBottom: "none",
                    padding: "7px",
                    borderTop: "1px black",
                    margin: "0px",
                    alignItems: "center",
                  }}
                >
                  {insReportData?.parent?.type?.charAt(0).toUpperCase() +
                    insReportData?.parent?.type?.slice(1)}{" "}
                  Details
                </th>
              </tr>
              <tr style={{ verticalAlign: "middle" }}>
                <th
                  colSpan="3"
                  style={{ border: "1px solid", wordWrap: "breakWord" }}
                >
                  {insReportData?.parent?.type?.charAt(0).toUpperCase() +
                    insReportData?.parent?.type?.slice(1)}{" "}
                  ID - {insReportData?.parent?.parent_id}
                </th>
                <th
                  colSpan="2"
                  style={{ border: "1px solid", wordWrap: "breakWord" }}
                >
                  {insReportData?.parent?.type?.charAt(0).toUpperCase() +
                    insReportData?.parent?.type?.slice(1)}{" "}
                  Name - {insReportData?.parent?.parent_name}
                </th>
                <th
                  colSpan="2"
                  style={{ border: "1px solid", wordWrap: "breakWord" }}
                >
                  {insReportData?.parent?.type?.charAt(0).toUpperCase() +
                    insReportData?.parent?.type?.slice(1)}{" "}
                  Location - {insReportData?.parent?.location}
                </th>
              </tr>
                <tr>
                <th
                  colSpan="7"
                  style={{ border: "1px solid", wordWrap: "breakWord" }}
                >
                  {insReportData?.parent?.type?.charAt(0).toUpperCase() +
                    insReportData?.parent?.type?.slice(1)}{" "}
                  Geo Location - Lat : {insReportData?.parent?.geo_loc?.coordinates?.length>0?convertToDMS(insReportData?.parent?.geo_loc?.coordinates[1],true):"NA"} & Long : {insReportData?.parent?.geo_loc?.coordinates?.length>0?convertToDMS(insReportData?.parent?.geo_loc?.coordinates[0],false):"NA"}
                </th>
                </tr>
              <tr>
                <th
                  colSpan="7"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "#0862B4",
                    color: "white",
                    textAlign: "center",
                    borderBottom: "none",
                    padding: "7px",
                    borderTop: "1px black",
                    margin: "0px",
                    alignItems: "center",
                  }}
                >
                  Task Details
                </th>
              </tr>
            </thead>
          </table>
        )}
      <table
        className="table"
        style={{
          width: 1000,
          margin: "auto",
          border: "1px solid black",
          borderBottom: "none",
          tableLayout: "fixed",
        }}
      >
        <thead style={{ verticalAlign: "middle" }}>
          <tr
            style={
              {
                // backgroundColor: "#0862B4",
                // color: "white",
                // fontWeight: "bold",
              }
            }
          >
            <th
              scope="col"
              style={{
                wordWrap: "break-word",
                width: "70px",
                border: "1px solid black",
              }}
            >
              Task No
            </th>
            <th
              scope="col"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Checkpoint
            </th>
            <th
              scope="col"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Standard Requirement
            </th>
            <th
              scope="col"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Response
            </th>
            <th
              scope="col"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Comments
            </th>
            <th
              scope="col"
              style={{
                width: "110px",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              Before Image
            </th>
            <th
              scope="col"
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              After Image
            </th>
          </tr>
        </thead>
        {insReportData?.checkpoint?.checkpoints.length !== 0
          ? insReportData?.checkpoint?.checkpoints.map((eachData, index) => {
              // console.log(eachData);
              return (
                <tbody style={{ width: "100%" }} key={index}>
                  <tr>
                    <td
                      style={{
                        wordWrap: "break-word",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      {eachData.checkpoint}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      {eachData.standard_requirement}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      {/* {(eachData.answer === "yes" || eachData.answer === "no") ?
                      eachData.answer.charAt(0).toUpperCase() + eachData.answer.slice(1) :
                      eachData.answer 
                    } */}
                      {eachData.answer === "yes"
                        ? eachData.answer.charAt(0).toUpperCase() +
                          eachData.answer.slice(1)
                        : eachData.answer === "no"
                        ? eachData.answer.charAt(0).toUpperCase() +
                          eachData.answer.slice(1)
                        : eachData.answer === "not_applicable"
                        ? "Not Applicable"
                        : eachData.answer}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        overflow: "hidden",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      {eachData.cp_comment}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        overflow: "hidden",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {eachData.before_pic ? (
                        <S3BucketImage
                          imageUrl={eachData.before_pic}
                          widthSize={"6rem"}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        overflow: "hidden",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {eachData.after_pic ? (
                        <S3BucketImage
                          imageUrl={eachData.after_pic}
                          widthSize={"6rem"}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                </tbody>
              );
            })
          : null}
      </table>
      <table
        className="table"
        style={{
          margin: "auto",
          width: 1000,
          border: "1px solid black",
          tableLayout: "fixed",
        }}
      >
        <tbody>
          <tr>
            <th
              colSpan="8"
              style={{
                textAlign: "left",
                padding: "7px",
                borderTop: "1px black solid",
                borderBottom: "1px black solid",
                margin: "0px",
                alignItems: "center",
                wordWrap: "break-word",
                fontWeight: "normal",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Additional Comments : &nbsp;
              </span>
              {insReportData?.inspection.common_comment}
            </th>
            {/* <th
              style={{
                border: "1px solid",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              {insReportData?.inspection?.signature ? (
                <S3BucketImage
                  imageUrl={insReportData?.inspection?.signature}
                  widthSize={"100px"}
                />
              ) : (
                "-NA-"
              )}
              <br />
              <span>Signature</span>
            </th> */}
          </tr>
          <tr>
            <th style={{
                border: "1px solid",
                textAlign: "left",
                verticalAlign: "middle",
              }} colSpan="2">
              {insReportData?.performer.name &&
              insReportData?.performer.name !==
                "undefined undefined undefined" ? (
                <div>{`Performed by - ${insReportData?.performer.name}`}</div>
              ) : (
                "Performed by - -"
              )}
            </th>
            <th colSpan="2">
              {insReportData?.performer.email ? (
                <div>{`Email - ${insReportData?.performer.email}`}</div>
              ) : (
                "Email - -"
              )}
            </th>
            <th colSpan="2"
            style={{
              border: "1px solid",
              textAlign: "left",
              verticalAlign: "middle",
            }}
            >
              {insReportData?.performer.designation ? (
                <div>{`Designation - ${(insReportData?.performer.designation).replace(
                  "_",
                  " "
                )}`}</div>
              ) : (
                "Designation - -"
              )}
            </th>
            <th
              colSpan="2"
              style={{
                border: "1px solid",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              {insReportData?.inspection?.signature ? (
                <S3BucketImage
                  imageUrl={insReportData?.inspection?.signature}
                  widthSize={"100px"}
                />
              ) : (
                "-NA-"
              )}
              <br />
              <span>Signature</span>
            </th>
          </tr>
          <tr>
            <th colSpan="8"
            style={{
              border: "1px solid",
              textAlign: "left",
              verticalAlign: "middle",
            }} 
            >
              {insReportData?.performer.name &&
              insReportData?.performer.name !==
                "undefined undefined undefined" ? (
                <div>{`Performer Geo Location - Lat : ${insReportData?.inspection?.geo_loc_performance?.coordinates?.length>0?convertToDMS(insReportData?.inspection?.geo_loc_performance?.coordinates[1],true):"NA"} & Long : ${insReportData?.inspection?.geo_loc_performance?.coordinates?.length>0?convertToDMS(insReportData?.inspection?.geo_loc_performance?.coordinates[0],false):"NA"}`}</div>
              ) : (
                `Performer Geo Location - Lat : NA & Long : NA`
              )}
            </th>
          </tr>
          <tr>
            <th colSpan="2" style={{
                border: "1px solid",
                textAlign: "left",
                verticalAlign: "middle",
              }}>
              {insReportData?.approver.name ? (
                <div>{`Approved by - ${insReportData?.approver.name}`}</div>
              ) : insReportData?.inspection?.trans_status ===
                "auto_approved" ? (
                "Approved by - Auto Approved"
              ) : (
                "Approved by - NA"
              )}
            </th>
            <th colSpan="2" style={{
                border: "1px solid",
                textAlign: "left",
                verticalAlign: "middle",
              }}>
              {insReportData?.approver.email ? (
                <div>{`Email - ${insReportData?.approver.email}`}</div>
              ) : (
                "Email - NA"
              )}
            </th>
            <th colSpan="2" style={{
                border: "1px solid",
                textAlign: "left",
                verticalAlign: "middle",
              }}>
              {insReportData?.approver.designation ? (
                <div>{`Designation - ${(insReportData?.approver.designation).replace(
                  "_",
                  " "
                )}`}</div>
              ) : (
                "Designation - NA"
              )}
            </th>
            <th
              colSpan="2"
              style={{
                border: "1px solid",
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              {insReportData?.inspection?.signature ? (
                <S3BucketImage
                  imageUrl={insReportData?.inspection?.approver_signature}
                  widthSize={"100px"}
                />
              ) : (
                "-NA-"
              )}
              <br />
              <span>Signature</span>
            </th>
          </tr>
          <tr>
            <th colSpan="8" style={{
                border: "1px solid",
                textAlign: "left",
                verticalAlign: "middle",
              }}>
              {insReportData?.approver.name ? (
                <div>{`Approver Geo Location - Lat : ${insReportData?.inspection?.geo_loc_approval?.coordinates?.length>0?convertToDMS(insReportData?.inspection?.geo_loc_approval?.coordinates[1],true):"NA"} & Long : ${insReportData?.inspection?.geo_loc_approval?.coordinates?.length>0?convertToDMS(insReportData?.inspection?.geo_loc_approval?.coordinates[0],false):"NA"}`}</div>
              ) : insReportData?.inspection?.trans_status ===
                "auto_approved" ? (
                `Approver Geo Location - Lat : NA & Long : NA`
              ) : (
                `Approver Geo Location - Lat : ${insReportData?.inspection?.geo_loc_approval?.coordinates?.length>0?convertToDMS(insReportData?.inspection?.geo_loc_approval?.coordinates[1],true):"NA"} & Long : ${insReportData?.inspection?.geo_loc_approval?.coordinates?.length>0?convertToDMS(insReportData?.inspection?.geo_loc_approval?.coordinates[0],false):"NA"}`
              )}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InspectionReport;
